import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { CellContainerService } from 'src/app/core/services/cell-container.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
  selector: 'app-view-cell-container',
  templateUrl: './view-cell-container.component.html',
  styleUrl: './view-cell-container.component.css'
})
export class ViewCellContainerComponent implements OnInit, OnDestroy {
    @ViewChild('mvtEntityAssociatorComponent', { static: false }) mvtEntityAssociatorComponent: MVTEntityAssociatorComponent;
    @Input() entitiesArray: Array<any> = [];

    private dataChanges: Subscription; 
    
    disabledButton: boolean = false;
    notFound:string = '';
    cellContainerData: any;
    
    constructor(
        private translate:TranslateService,
        public recordLockingFlow: RecordLockingFlow,
        private cellContainerService: CellContainerService
    ){}

    ngOnInit():void {
        this.notFound = this.translate.instant(`common.noDataTable`);

        this.dataChanges = this.cellContainerService.dataChange$.subscribe((data) => {
            this.cellContainerData = data;
        });
    }

    ngOnDestroy(): void {
        this.dataChanges?.unsubscribe();    
    }

    getColumnsInfoCELL(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'contactId', columnHeader: 'clearLock.contactId', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'lockId', columnHeader: 'clearLock.lockId', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 30});
        columnsInfo.push({ entityPropName: 'entityId', columnHeader: 'clearLock.entityID', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 55});

        return columnsInfo;
    }

    getColumnsInfoCGLL(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'entityName', columnHeader: 'clearLock.entityName', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'entityId', columnHeader: 'clearLock.entityID', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 30});
        columnsInfo.push({ entityPropName: 'lockId', columnHeader: 'clearLock.lockId', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 55});
        columnsInfo.push({ entityPropName: 'entityEditedLockId', columnHeader: 'clearLock.entityEditedLockId', editableType: ColumnEntityInfoEditableType.numberField, widthPercentage: 55});

        return columnsInfo;
    }

}
