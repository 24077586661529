import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-selected-items-modall',
    templateUrl: './selected-items-modal.component.html'
})

export class SelectedItemsModalComponent implements OnInit {

    entitiesArray: any[];
    entityDisplayPropName: string;

    constructor(
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig) {
    }

    ngOnInit(): void {
        this.entitiesArray = this.config.data.entitiesArray;
        this.entityDisplayPropName = this.config.data.entityDisplayPropName;
    }

    static openSearchModal(dialogService: DialogService, entitiesArray: any[], entityDisplayPropName: string, title: string = 'Selected Items'): void {
        if (!entitiesArray || entitiesArray.length === 0) {
            return;
        }
        
        dialogService.open(SelectedItemsModalComponent, {
            header: title,
            width: '30rem',
            data: {
                entitiesArray: entitiesArray,
                entityDisplayPropName: entityDisplayPropName,
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
    }

}
