import { StringUtils } from "../utils/string-utils";
import {OperationType} from "./enumerations/operation-type";

export abstract class Bean {
    private operation: number;
    private _edited: boolean;

    updateType: number;
    saveType: number;
    lockId: number;
    lockMode: string;
    lockError: string;
    lockSameUser: boolean;
    lockSameSession: boolean;

    intDataDepAmendState: number;
    intDataDepValState: number;
    intDataDepMigState: number;
    intDataDepMigError: string;
    intDataDepUpdateTime: string;
    releaseApproval: number;

    changedFields: {};
    initialData:{};

    static UPDATE_TYPE_IDLE_WIP_AMEND = 0;
    static UPDATE_TYPE_UPDATE = 1;
    static UPDATE_TYPE_QC_MINOR = 2;
    static UPDATE_TYPE_QC_MAJOR = 3;
    static UPDATE_TYPE_QC_NEW = 4;

    protected constructor(entity?: any) {
      this.operation = entity?.operation ?? OperationType.IDLE;

      this.updateType = entity?.updateType ?? 0;
      this.saveType = entity?.saveType ?? 0;
      this.lockId = entity?.lockId ?? null;
      this.lockMode = entity?.lockMode ?? null;
      this.lockError = entity?.lockError ?? null;
      this.lockSameUser = entity?.lockSameUser ?? false;
      this.lockSameSession = entity?.lockSameSession ?? false;

      this.intDataDepAmendState = entity?.intDataDepAmendState ?? 0;
      this.intDataDepValState = entity?.intDataDepValState ?? 0;
      this.intDataDepMigState = entity?.intDataDepMigState ?? 0;
      this.intDataDepMigError = entity?.intDataDepMigError ?? null;
      this.intDataDepUpdateTime = entity?.intDataDepUpdateTime ?? null;
      this.releaseApproval = entity?.releaseApproval ?? 0;

      this.changedFields = entity?.changedFields ?? {};
    }

    LoadBeanSection(entity: any) {
        this.updateType = entity.updateType;
        this.saveType = entity.saveType;
        this.operation = entity.operation;
        this.lockId = entity.lockId;
        this.intDataDepValState = entity.intDataDepValState;
        this.changedFields = entity.changedFields;
    }

    getOperation(): number {
      return this.operation;
    }

    setOperation(value: number): void {
      // We do not want to set the action as update if it was already an insert, because it has to be released at least once
      if(this.operation === OperationType.INSERT && value !== OperationType.DELETE) {
        return;
      }

      if(this.operation === OperationType.TEMPORAL && value !== OperationType.INSERT) {
        return;
      }

      if(this.operation === OperationType.UPDATE && value === OperationType.INSERT) {
        return;
      }

      if(value === OperationType.DUPLICATE) {
        value = OperationType.INSERT;
      }

      this.operation = value;
    }

    get edited(): boolean {
        return this._edited || Object.getOwnPropertyNames(this.changedFields).length > 0
    }

    set edited(value: boolean) {
        this._edited = value;
    }

    get updateTypeBool(): boolean {
        return this.updateType === Bean.UPDATE_TYPE_QC_MAJOR;
    }

    forceUpdateBasicFields(): void {
        this.changedFields = {'controlChangedField': 1};
    }

    forceBeanChange(property: string): void {
        if (!StringUtils.isEmpty(property)) {
            this.changedFields[property] = 1;
        }
    }

    fieldWasEdited(field: any): boolean {
        if(this.changedFields[field] !== undefined && this.changedFields[field] !== null){
            return true;
        }else{
            return false;
        }
    }

    applyChanges(toApplyProps:any): void {
        if(toApplyProps){
            let updateOperation = false;
            for (let property in toApplyProps) {
                if (this.hasOwnProperty(property)) {
                    const currentValue = this[property];
                    const newValue = toApplyProps[property];

                    if(currentValue !== newValue) {
                        this[property] = newValue;
                        this.forceBeanChange(property);
                        updateOperation = true;
                    }
                }
            }

            if(updateOperation) {
                this.setOperation(OperationType.UPDATE);
            }
        }
    }

    detectChangedFields(exludedProperties?: string[]): string[] {
        let fields: string[] = [];
        var propNames = Object.getOwnPropertyNames(this);
        propNames.forEach((propName) => {
            if((exludedProperties == null || !exludedProperties.includes(propName))) {
                if (this && this[propName]) {
                    if (!StringUtils.isEmptyInPrimitiveTypes(this[propName])) {
                        this.forceBeanChange(propName);
                        fields.push(propName);
                    }
                } 
            }
        });
        return fields;
    }

    public hasDiffUserLockError(): boolean {
        return (this.lockError != null && !this.lockSameUser);
    }

    static CreateInstancesFromArray<T extends Bean>(this: { new (entity?: any): T }, entities: Array<any>): Array<T> {
        return entities.map(item => new this(item));
    }

    static CreateEntityInstance<T extends Bean>(this: new (entity?: any) => T, entity: any): T {
        return new this(entity);
    }

}    
