
<div class="col-12">
    <p-listbox
        [options]="entitiesArray"
        [optionLabel]="entityDisplayPropName"
        [style]="{'width':'max'}"
        emptyFilterMessage=""
        emptyMessage=""
        [tabIndex]="-1">
    </p-listbox>
</div>
