import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
// Third Party
import { MenuItem } from 'primeng/api';
// Rxjs
import { Observable, Subscription, forkJoin, tap } from 'rxjs';
// Services
import { AuthService } from 'src/app/auth/auth.service';
import { AsideService, IMenu } from '../aside.service';
// Model
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ClearLockUtilityComponent } from 'src/app/components/modals/clear-lock-utility/clear-lock-utility.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { Role } from 'src/app/core/models/enumerations/role';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { CellContainerService } from 'src/app/core/services/cell-container.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { HeaderService } from '../../header/header.service';

@Component({
  selector: 'app-nav-group-item',
  templateUrl: './nav-group-item.component.html',
  styleUrls: ['./nav-group-item.component.scss']
})
export class NavGroupItemComponent implements OnInit, OnDestroy {
    items: MenuItem[] = [];
    private menuChanged: Subscription;
    private preferencesItems: MenuItem[];
    private companyItems: MenuItem[];
    private siteItems: MenuItem[];
    private pipelineItems: MenuItem[];
    private transmissionLineItems: MenuItem[];
    private plantItems: MenuItem[];
    private plantLtsaItems: MenuItem[];
    private unitLtsaItems: MenuItem[];
    private pipelineLtsaItems: MenuItem[];
    private confirmedProjectItems: MenuItem[];
    private unconfirmedProjectItems: MenuItem[];
    private unitItems: MenuItem[];
    private driveItems: MenuItem[];
    private equipmentItems: MenuItem[];
    private mEquipmentItems: MenuItem[];
    private tankItems: MenuItem[];
    private boilerItems: MenuItem[];
    private umbrellaProjectItems: MenuItem[];
    private areaItems: MenuItem[];
    private home: MenuItem[];
    private releaseItems: MenuItem[];
    private offlineEventItems: MenuItem[];
    private geographyItems: MenuItem[];
    private regionAlertingItems: MenuItem[];
    private subscriptionEditItems: MenuItem[];
    private subscriptionCopyItems: MenuItem[];
    private codeFileItems: MenuItem[];

    private hasCompanyScreen: boolean = false;
    private hasSiteScreen: boolean = false;
    private hasPipelineScreen: boolean = false;
    private hasTLineScreen: boolean = false;
    private hasPlantScreen: boolean = false;
    private hasAreaScreen: boolean = false;
    private hasUnitScreen: boolean = false;
    private hasBoilerScreen: boolean = false;
    private hasDriveScreen: boolean = false;
    private hasTankScreen: boolean = false;
    private hasMEquipmentScreen: boolean = false;
    private hasOEquipmentScreen: boolean = false;
    private hasOfflineEventScreen: boolean = false;
    private hasConfirmedProjectScreen: boolean = false;
    private hasUnconfirmedProjectScreen: boolean = false;
    private hasPlantLTSAScreen: boolean = false;
    private hasUnitLTSAScreen: boolean = false;
    private hasPipelineLTSAScreen: boolean = false;
    private hasReleaseScreen: boolean = false;

    private hasAECFirmRole = false;
    private hasManufacturerRole = false;
    private hasServiceContractorRole = false;
    private hasCrudeSlateTypeRole = false;
    private hasKvRatingRole = false;
    private hasTransmissionSystemRole = false;
    private hasPipelineSystemRole = false;
    private hasDrivenEquipmentRole = false;
    private hasEquipmentTypeRole = false;
    private hasHsProductRole = false;
    private hasPipelineMeterRole = false;

    private hasAnyCodefileRole: boolean = false;
    
    private hasRegionAlertingRole: boolean = false;
    private hasClearLockRole: boolean = false;
    private hasManageGeographyRole: boolean = false;
    private hasSubscriptionRole: boolean = false;
    
    @Output() onScrollOption: EventEmitter<string> = new EventEmitter();

    constructor(private entityCommonService: EntityCommonService,
                private authService: AuthService,
                private router: Router,
                private asideService: AsideService,
                private headerService: HeaderService,
                public dialogService: DialogService,
                private cellContainerService: CellContainerService) {
    }

    ngOnInit() {
        this.loadMenuItems();
        forkJoin([ this.loadScreens(), this.loadRoles() ]).subscribe(() => {
            // All loader functions are now complete, so we can utilize that information to set up the menu 
            this.configureMenu();
        });  
    }

    ngOnDestroy(): void {
       this.menuChanged?.unsubscribe();
    }

    private loadScreens(): Observable<any> {
        return forkJoin([
            this.authService.hasUserScreen(EntityName.COMPANY).pipe(tap(b => this.hasCompanyScreen = b)),
            this.authService.hasUserScreen(EntityName.SITE).pipe(tap(b => this.hasSiteScreen = b)),
            this.authService.hasUserScreen(EntityName.ASSET_PIPELINE).pipe(tap(b => this.hasPipelineScreen = b)),
            this.authService.hasUserScreen(EntityName.ASSET_TRANSMISSION_LINE).pipe(tap(b => this.hasTLineScreen = b)),
            this.authService.hasUserScreen(EntityName.PLANT).pipe(tap(b => this.hasPlantScreen = b)),
            this.authService.hasUserScreen(EntityName.AREA).pipe(tap(b => this.hasAreaScreen = b)),
            this.authService.hasUserScreen(EntityName.UNIT).pipe(tap(b => this.hasUnitScreen = b)),
            this.authService.hasUserScreen(EntityName.BOILER).pipe(tap(b => this.hasBoilerScreen = b)),
            this.authService.hasUserScreen(EntityName.TURBINE).pipe(tap(b => this.hasDriveScreen = b)),
            this.authService.hasUserScreen(EntityName.TANK).pipe(tap(b => this.hasTankScreen = b)),
            this.authService.hasUserScreen(EntityName.MEQUIPMENT).pipe(tap(b => this.hasMEquipmentScreen = b)),
            this.authService.hasUserScreen(EntityName.OEQUIPMENT).pipe(tap(b => this.hasOEquipmentScreen = b)),
            this.authService.hasUserScreen(EntityName.OFFLINE_EVENT).pipe(tap(b => this.hasOfflineEventScreen = b)),
            this.authService.hasUserScreen(EntityName.PROJECT_CONFIRMED).pipe(tap(b => this.hasConfirmedProjectScreen = b)),
            this.authService.hasUserScreen(EntityName.PROJECT_UNCONFIRMED).pipe(tap(b => this.hasUnconfirmedProjectScreen = b)),
            this.authService.hasUserScreen(EntityName.PLANT_LTSA).pipe(tap(b => this.hasPlantLTSAScreen = b)),
            this.authService.hasUserScreen(EntityName.UNIT_LTSA).pipe(tap(b => this.hasUnitLTSAScreen = b)),
            this.authService.hasUserScreen(EntityName.PIPELINE_LTSA).pipe(tap(b => this.hasPipelineLTSAScreen = b)),
            this.authService.hasUserScreen(EntityName.RELEASE).pipe(tap(b => this.hasReleaseScreen = b))
        ]);
    }

    private loadRoles(): Observable<any> {
        return forkJoin([
            this.authService.isUserInRole(Role.CLEAR_LOCKS).pipe(tap(b => this.hasClearLockRole = b)),
            this.authService.isUserInRole(Role.MANAGE_GEOGRAPHY).pipe(tap(b => this.hasManageGeographyRole = b)),
            this.authService.isUserInRole(Role.EDIS_ACCESS_ADMIN).pipe(tap(b => this.hasSubscriptionRole = b)),
            this.authService.isUserInRole(Role.EDIS_ALERT_REGION).pipe(tap(b => this.hasRegionAlertingRole = b)),
            this.loadCodefileRoles()
        ]);
    }

    private loadCodefileRoles(): Observable<any> {
        return forkJoin([        
            this.authService.isUserInRole(Role.MANAGE_AEC).pipe(tap(b => this.hasAECFirmRole = b)),
            this.authService.isUserInRole(Role.MANAGE_MFG).pipe(tap(b => this.hasManufacturerRole = b)),
            this.authService.isUserInRole(Role.MANAGE_SERV_CONTR).pipe(tap(b => this.hasServiceContractorRole = b)),
            this.authService.isUserInRole(Role.MANAGE_CRUDE_SLATE).pipe(tap(b => this.hasCrudeSlateTypeRole = b)),
            this.authService.isUserInRole(Role.MANAGE_KV_RATINGS).pipe(tap(b => this.hasKvRatingRole = b)),
            this.authService.isUserInRole(Role.MANAGE_PIPELINE_SYSTEM).pipe(tap(b => this.hasPipelineSystemRole = b)),
            this.authService.isUserInRole(Role.MANAGE_DRIVEN_EQUIPMENT).pipe(tap(b => this.hasDrivenEquipmentRole = b)),
            this.authService.isUserInRole(Role.MANAGE_HS_PRODUCT).pipe(tap(b => this.hasHsProductRole = b)),
            this.authService.isUserInRole(Role.MANAGE_TRANSMISSION_SYSTEM).pipe(tap(b => this.hasTransmissionSystemRole = b)),
            this.authService.isUserInRole(Role.MANAGE_EQUIPMENT).pipe(tap(b => this.hasEquipmentTypeRole = b)),
            this.authService.isUserInRole(Role.MANAGE_PIPELINE_METER).pipe(tap(b => this.hasPipelineMeterRole = b)),
        ]).pipe(tap((a: Array<boolean>) => this.hasAnyCodefileRole = a.some(e => e === true)));
    }

    private loadMenuItems() {
        this.preferencesItems = [
            {
                label: 'Preferences Details',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Preferences', command: (click) => {this.scrollToSection('preferences')}, visible: true }
                ]
            }
        ];
        this.companyItems = [
            {
                label: 'Company Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Details', command: (click) => {this.scrollToSection('details')}, visible: true },
                    { label: 'Company Types', command: (click) => {this.scrollToSection('types')}, visible: true },
                    { label: 'Greenhouse Gas',  command: (click) => {this.scrollToSection('greenhouseGas')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.siteItems = [
            {
                label: 'Site Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            }
        ];

        this.pipelineItems = [
            {
                label: 'Pipeline Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Pipeline Path', command: (click) => {this.scrollToSection('path')}, visible: true },
                    { label: 'Pipeline Connections', command: (click) => {this.scrollToSection('connections')}, visible: true },
                    { label: 'Pipeline Laterals',  command: (click) => {this.scrollToSection('laterals')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.transmissionLineItems = [
            {
                label: 'Transmission Line Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'T-Line Path', command: (click) => {this.scrollToSection('path')}, visible: true },
                    { label: 'T-Line Connections', command: (click) => {this.scrollToSection('connections')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.plantItems = [
            {
                label: 'Plant Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Connected Plants', command: (click) => {this.scrollToSection('connectedPlants')}, visible: true },
                    { label: 'Ownership',  command: (click) => {this.scrollToSection('ownership')}, visible: true },
                    { label: 'Industrial Info',  command: (click) => {this.scrollToSection('industrialInfo')}, visible: true },
                    { label: 'Energy Details',  command: (click) => {this.scrollToSection('energyDetails')}, visible: true },
                    { label: 'Greenhouse Gas',  command: (click) => {this.scrollToSection('greenhouseGas')}, visible: true },
                    { label: 'Pipeline Meters',  command: (click) => {this.scrollToSection('pipelineMeters')}, visible: true },
                    { label: 'Operational Details',  command: (click) => {this.scrollToSection('operationalDetails')}, visible: true },
                    { label: 'Capacity',  command: (click) => {this.scrollToSection('capacity')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Alerts',  command: (click) => {this.scrollToSection('alerts')}, visible: true },
                    { label: 'Mine Info',  command: (click) => {this.scrollToSection('mineInfo')}, visible: false}
                ]
            },
        ];

        this.plantLtsaItems = [
            {
                label: 'Plant LTSA Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Service Agreement Types', command: (click) => {this.scrollToSection('serviceAgreementTypes')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.unitLtsaItems = [
            {
                label: 'Unit LTSA Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true }
                ]
            },
        ];

        this.pipelineLtsaItems = [
            {
                label: 'Pipeline LTSA Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Service Agreement Types', command: (click) => {this.scrollToSection('serviceAgreementTypes')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true }
                ]
            },
        ];

        this.confirmedProjectItems = [
            {
                label: 'Confirmed Projects Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Units',  command: (click) => {this.scrollToSection('units')}, visible: true },
                    { label: 'Offline Events',  command: (click) => {this.scrollToSection('offlineEvents')}, visible: true },
                    { label: 'Scope and Schedule',  command: (click) => {this.scrollToSection('scopeAndSchedule')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Expanded Needs',  command: (click) => {this.scrollToSection('expandedNeeds')}, visible: true },
                    { label: 'Definitive Needs',  command: (click) => {this.scrollToSection('definitiveNeeds')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Pipeline Details',  command: (click) => {this.scrollToSection('pipelineDetails')}, visible: false }
                ]
            },
        ];

        this.unconfirmedProjectItems = [
            {
                label: 'Unconfirmed Projects Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Units',  command: (click) => {this.scrollToSection('units')}, visible: true },
                    { label: 'Offline Events',  command: (click) => {this.scrollToSection('offlineEvents')}, visible: true },
                    { label: 'Scope and Schedule',  command: (click) => {this.scrollToSection('scopeAndSchedule')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Expanded Needs',  command: (click) => {this.scrollToSection('expandedNeeds')}, visible: true },
                    { label: 'Definitive Needs',  command: (click) => {this.scrollToSection('definitiveNeeds')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true }
                ]
            },
        ];

        this.umbrellaProjectItems = [
            {
                label: 'Umbrella Projects Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Summary', command: (click) => {this.scrollToSection('summary')}, visible: true }
                ]
            },
        ];

        this.unitItems = [
            {
                label: 'Unit Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Equipment',  command: (click) => {this.scrollToSection('equipment')}, visible: true },
                    { label: 'Industrial Info',  command: (click) => {this.scrollToSection('industrialInfo')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Capacity',  command: (click) => {this.scrollToSection('capacity')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true },
                    { label: 'Crude Slate',  command: (click) => {this.scrollToSection('crudeSlate')}, visible: false }
                ]
            },
        ];

        this.tankItems = [
            {
                label: 'Tank Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true }
                ]
            },
        ];

        this.driveItems = [
            {
                label: 'Drive Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true }
                ]
            },
        ];

        this.boilerItems = [
            {
                label: 'Boiler Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true }
                ]
            },
        ];

        this.equipmentItems = [
            {
                label: 'Other Equipment Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.mEquipmentItems = [
            {
                label: 'Mining Equipment Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main',  command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.areaItems = [
            {
                label: 'Area Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Contacts',  command: (click) => {this.scrollToSection('contacts')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Notes',  command: (click) => {this.scrollToSection('notes')}, visible: true }
                ]
            },
        ];

        this.releaseItems = [
            {
                label: 'Release',
                icon: 'pi pi-file-export',
                visible: true,
                expanded: true,
                items: []
            },
        ];
        
        this.offlineEventItems = [
            {
                label: 'Offline Event Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Equipment',  command: (click) => {this.scrollToSection('equipment')}, visible: true },
                    { label: 'Related Info',  command: (click) => {this.scrollToSection('relatedInfo')}, visible: true },
                    { label: 'Alerts',  command: (click) => {this.scrollToSection('alerts')}, visible: true }
                ]
            },
        ];
        
        this.geographyItems = [
            {
                label: 'Geography Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true }
                ]
            },
        ];

        this.regionAlertingItems = [
            {
                label: 'Region Alerting Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true },
                    { label: 'Alert History', command: (click) => {this.scrollToSection('alertHistory')}, visible: true },
                ]
            },
        ];

        this.subscriptionEditItems = [
            {
                label: 'Edit Subscription Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Edit Subscription', command: (click) => {this.scrollToSection('main')}, visible: true },
                ]
            },
        ];

        this.subscriptionCopyItems = [
            {
                label: 'Copy Subscription Input',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Copy Subscription', command: (click) => {this.scrollToSection('main')}, visible: true },
                ]
            },
        ];

        this.codeFileItems = [
            {
                label: '',
                icon: 'pi pi-list',
                visible: true,
                expanded: true,
                items: [
                    { label: 'Main', command: (click) => {this.scrollToSection('main')}, visible: true }
                ]
            },
        ];

        this.menuChanged = this.asideService.menuChanged$.subscribe(menuData => {
            this.toggleMenu(menuData);
        });        
    }
   
   private configureMenu(): void {
        

        this.home = [
            {
                label: 'Entity Screens',
                visible: true,
                expanded: true,
                icon: 'pi pi-file-edit',
                items: [
                    {
                        label: 'Company',
                        command: (click) => {this.goTo('company', EntityName.COMPANY)},
                        visible: this.hasCompanyScreen
                    },

                    {
                        label: 'Asset',
                        visible: this.hasSiteScreen || this.hasPipelineScreen || this.hasTLineScreen,
                        items: [
                            {
                                label: 'Site',
                                command: (click) => {this.goTo('site', EntityName.SITE)},
                                styleClass: 'submenu-item',
                                visible: this.hasSiteScreen
                            },

                            {
                                label: 'Pipeline',
                                command: (click) => {this.goTo('pipeline', EntityName.ASSET_PIPELINE)},
                                styleClass: 'submenu-item',
                                visible: this.hasPipelineScreen
                            },

                            {
                                label: 'Transmission Line',
                                command: (click) => {this.goTo('transmissionLine', EntityName.ASSET_TRANSMISSION_LINE)},
                                styleClass: 'submenu-item',
                                visible: this.hasTLineScreen
                            },
                        ],
                    },
                    {
                        label: 'Plant',
                        command: (click) => {this.goTo('plant', EntityName.PLANT)},
                        visible: this.hasPlantScreen
                    },

                    {
                        label: 'Area',
                        command: (click) => {this.goTo('area', EntityName.AREA)},
                        visible: this.hasAreaScreen,
                    },

                    {
                        label: 'Unit',
                        command: (click) => {this.goTo('unit', EntityName.UNIT)},
                        visible: this.hasUnitScreen
                    },

                    {
                        label: 'Equipment',
                        visible: this.hasBoilerScreen || this.hasDriveScreen || this.hasTankScreen || this.hasMEquipmentScreen || this.hasOEquipmentScreen,
                        items: [
                            {
                                label: 'Boiler',
                                command: (click) => {this.goTo('boiler', EntityName.BOILER)},
                                styleClass: 'submenu-item',
                                visible: this.hasBoilerScreen
                            },

                            {
                                label: 'Drive',
                                command: (click) => {this.goTo('drive', EntityName.TURBINE)},
                                styleClass: 'submenu-item',
                                visible: this.hasDriveScreen
                            },

                            {
                                label: 'Tank',
                                command: (click) => {this.goTo('tank', EntityName.TANK)},
                                styleClass: 'submenu-item',
                                visible: this.hasTankScreen
                            },

                            {
                                label: 'Mining Equipment',
                                command: (click) => {this.goTo('miningEquipment', EntityName.MEQUIPMENT)},
                                styleClass: 'submenu-item',
                                visible: this.hasMEquipmentScreen
                            },

                            {
                                label: 'Other Equipment',
                                command: (click) => {this.goTo('otherEquipment', EntityName.OEQUIPMENT)},
                                styleClass: 'submenu-item',
                                visible: this.hasOEquipmentScreen
                            },
                        ],
                    },

                    {
                        label: 'Offline Event',
                        command: (click) => {this.goTo('offlineEvent', EntityName.OFFLINE_EVENT)},
                        visible: this.hasOfflineEventScreen,
                    },

                    {
                        label: 'Project',
                        visible:  this.hasConfirmedProjectScreen || this.hasUnconfirmedProjectScreen,
                        items: [
                            {
                                label: 'Confirmed Project',command: (click) => {this.goTo('confirmedProject', EntityName.PROJECT_CONFIRMED)},
                                styleClass: 'submenu-item',
                                visible: this.hasConfirmedProjectScreen
                            },

                            {
                                label: 'Unconfirmed Project',
                                command: (click) => {this.goTo('unconfirmedProject', EntityName.PROJECT_UNCONFIRMED)},
                                styleClass: 'submenu-item',
                                visible: this.hasUnconfirmedProjectScreen
                            },

                            {
                                label: 'Umbrella Project',
                                command: (click) => {this.goTo('umbrellaProject', EntityName.UMBRELLA_PROJECT)},
                                styleClass: 'submenu-item',
                                visible: this.hasConfirmedProjectScreen
                            },
                        ],
                    },

                    {
                        label: 'LTSA',
                        visible: this.hasPlantLTSAScreen || this.hasUnitLTSAScreen || this.hasPipelineLTSAScreen,
                        items: [
                            {
                                label: 'Plant LTSA',
                                command: (click) => {this.goTo('plantLtsa', EntityName.PLANT_LTSA)},
                                styleClass: 'submenu-item',
                                visible: this.hasPlantLTSAScreen
                            },

                            {
                                label: 'Unit LTSA',
                                command: (click) => {this.goTo('unitLtsa', EntityName.UNIT_LTSA)},
                                styleClass: 'submenu-item',
                                visible: this.hasUnitLTSAScreen
                            },

                            {
                                label: 'Pipeline LTSA',
                                command: (click) => {this.goTo('pipelineLtsa', EntityName.PIPELINE_LTSA)},
                                styleClass: 'submenu-item',
                                visible: this.hasPipelineLTSAScreen
                            }
                        ],
                    },

                    {
                        label: 'Region Alerting',
                        command: (click) => {this.goTo('regionAlerting', EntityName.REGION_ALERTING)},
                        visible: this.hasRegionAlertingRole
                    },

                    {
                        label: 'Release',
                        command: (click) => {this.goTo('release', EntityName.RELEASE)},
                        visible: this.hasReleaseScreen
                    },
                ]
            },

            {
                label: 'Admin',
                icon: 'pi pi-key',
                visible: this.hasClearLockRole || this.hasManageGeographyRole || this.hasSubscriptionRole || this.hasAnyCodefileRole,
                expanded: true,
                items: [
                    {
                        label: 'Geography',
                        command: (click) => {this.goTo('geography', EntityName.GEOGRAPHY)},
                        visible: this.hasManageGeographyRole
                    },
                    {
                        label: 'Code Files',
                        visible: this.hasAnyCodefileRole,
                        items: [
                            this.getMenuItem(EntityName.AEC_FIRM, this.hasAECFirmRole, true),
                            this.getMenuItem(EntityName.MANUFACTURER, this.hasManufacturerRole, true),
                            this.getMenuItem(EntityName.SERV_CONTRACTOR, this.hasServiceContractorRole, true),
                            this.getMenuItem(EntityName.CRUDE_SLATE_TYPE, this.hasCrudeSlateTypeRole, true),
                            this.getMenuItem(EntityName.KV_RATING, this.hasKvRatingRole, true, 'kV Ratings'),
                            this.getMenuItem(EntityName.PIPELINE_SYSTEM, this.hasPipelineSystemRole, true),
                            this.getMenuItem(EntityName.DRIVEN_EQUIPMENT_TYPE, this.hasDrivenEquipmentRole, true, 'Driven Equipment'),
                            this.getMenuItem(EntityName.HS_PRODUCT, this.hasHsProductRole, true, 'HS Products'),
                            this.getMenuItem(EntityName.TRANSMISSION_SYSTEM, this.hasTransmissionSystemRole, true),
                            this.getMenuItem(EntityName.EQUIPMENT_TYPE, this.hasEquipmentTypeRole, true, 'Equipment Types'),
                            this.getMenuItem(EntityName.PIPELINE_METER, this.hasPipelineMeterRole, true),
                        ],
                    },
                    {
                        label: 'Subscription',
                        visible: this.hasSubscriptionRole,
                        items: [
                            {
                                label: 'Edit Subscription',
                                command: (click) => {this.goTo('subscriptionEdit', EntityName.SUBSCRIPTION_EDIT)},
                                styleClass: 'submenu-item',
                                visible: this.hasSubscriptionRole
                            },
                            {
                                label: 'Copy Subscription',
                                command: (click) => {this.goTo('subscriptionCopy', EntityName.SUBSCRIPTION_COPY)},
                                styleClass: 'submenu-item',
                                visible: this.hasSubscriptionRole
                            }
                        ],
                    },
                    {
                        label: 'Clear Lock Utility',
                        command: (click) => {this.openClearLockUtility()},
                        visible: this.hasClearLockRole
                    },
                ]
            }
        ];

        if(this.items.length == 0) {
            this.items = this.home;
            this.headerService.clearReleaseSection();
        }

        const currentUrl = this.router.url;
        if(currentUrl.indexOf('/company') > 0) {
            this.toggleMenu({name: 'company'});
        } else if(currentUrl.indexOf('/dashboard') > 0) {
            this.toggleMenu({name: 'home'});
        } else if(currentUrl.indexOf('/preferences') > 0) {
            this.toggleMenu({name: 'preferences'});
        } else if(currentUrl.indexOf('/site') > 0) {
            this.toggleMenu({name: 'site'});
        } else if(currentUrl.indexOf('/transmissionLine') > 0) {
            this.toggleMenu({name: 'transmissionLine'});
        } else if(currentUrl.indexOf('/plantLtsa') > 0) {
            this.toggleMenu({name: 'plantLtsa'});
        } else if(currentUrl.indexOf('/unitLtsa') > 0) {
            this.toggleMenu({name: 'unitLtsa'});
        } else if(currentUrl.indexOf('/pipelineLtsa') > 0) {
            this.toggleMenu({name: 'pipelineLtsa'});
        } else if(currentUrl.indexOf('/plant') > 0) {
            this.toggleMenu({name: 'plant'});
        } else if(currentUrl.indexOf('/pipeline') > 0) {
            this.toggleMenu({name: 'pipeline'});
        } else if(currentUrl.indexOf('/confirmedProject') > 0) {
            this.toggleMenu({name: 'confirmedProject'});
        } else if(currentUrl.indexOf('/unconfirmedProject') > 0) {
            this.toggleMenu({name: 'unconfirmedProject'});
        } else if(currentUrl.indexOf('/unit') > 0) {
            this.toggleMenu({name: 'unit'});
        } else if(currentUrl.indexOf('/tank') > 0) {
            this.toggleMenu({name: 'tank'});
        } else if(currentUrl.indexOf('/drive') > 0) {
            this.toggleMenu({name: 'drive'});
        } else if(currentUrl.indexOf('/otherEquipment') > 0) {
            this.toggleMenu({name: 'otherEquipment'});  
        } else if(currentUrl.indexOf('/miningEquipment') > 0) {
            this.toggleMenu({name: 'miningEquipment'});    
        } else if(currentUrl.indexOf('/umbrellaProject') > 0) {
            this.toggleMenu({name: 'umbrellaProject'});
        } else if(currentUrl.indexOf('/area') > 0) {
            this.toggleMenu({name: 'area'});
        } else if(currentUrl.indexOf('/boiler') > 0) {
            this.toggleMenu({name: 'boiler'});
        } else if(currentUrl.indexOf('/release') > 0) {
            this.toggleMenu({name: 'release'});
        } else if(currentUrl.indexOf('/offlineEvent') > 0) {
            this.toggleMenu({name: 'offlineEvent'});
        } else if(currentUrl.indexOf('/geography') > 0) {
            this.toggleMenu({name: 'geography'});
        } else if(currentUrl.indexOf('/regionAlerting') > 0) {
            this.toggleMenu({name: 'regionAlerting'});
        } else if(currentUrl.indexOf('/subscriptionEdit') > 0) {
            this.toggleMenu({name: 'subscriptionEdit'});
        } else if(currentUrl.indexOf('/subscriptionCopy') > 0) {
            this.toggleMenu({name: 'subscriptionCopy'});
        } else {
            this.toggleCodeFilesMenus(currentUrl);
        }
        this.asideService.changeMenuState();

   }

    toggleCodeFilesMenus(currentUrl: string): void {
        const entityNames = [EntityName.AEC_FIRM, EntityName.MANUFACTURER, EntityName.SERV_CONTRACTOR, EntityName.CRUDE_SLATE_TYPE, EntityName.KV_RATING, EntityName.PIPELINE_SYSTEM, EntityName.DRIVEN_EQUIPMENT_TYPE, EntityName.HS_PRODUCT, EntityName.TRANSMISSION_SYSTEM, EntityName.EQUIPMENT_TYPE, EntityName.PIPELINE_METER];
        entityNames.some(entityName => {
            const entityUrl = EntityUtilsService.getEntityPath(entityName);
            if(currentUrl.indexOf('/'+entityUrl) > 0) {
                this.toggleMenuByEntityName({name: entityUrl}, entityName, this.codeFileItems, false, false);
                return true;
            }
        })
    }
    
    toggleMenuByEntityName(option:IMenu, entityName: EntityName, items: MenuItem[], showPDFLink: boolean, showClientView: boolean = true): void {
        const entityUrl = EntityUtilsService.getEntityPath(entityName);
        if(option.name === entityUrl) {
            let title: string = this.headerService.getEntityTitle(entityName);
            items[0].label = title + ' Input',
            this.items = this.getVisibleMenuItems(items, option);
            this.headerService.changeHeader(title, true, entityName, {showClientView: showClientView, showPDFLink: showPDFLink});
        }
        
        this.addItemsActions(this.items, option);
    }

    getMenuItem(entityName: EntityName, visible: boolean, submenu: boolean, label: string = null): any {
        return {
            label: label ?? entityName,
            command: (click) => {this.goTo(EntityUtilsService.getEntityPath(entityName), this.headerService.getEntityTitle(entityName))},
            styleClass: submenu ? 'submenu-item' : 'padding-l',
            visible: visible
        };
    }
   


    scrollToSection(fragment: string){
        const element:HTMLElement = document.querySelector ("#" +fragment);
        if(element){      
            let headerElement:HTMLElement = document.querySelector(".header-entity");
            this.onScrollOption.emit();  
            setTimeout( ()=> window.scrollTo({  
                top: element.offsetTop - headerElement.offsetHeight,
                behavior: "smooth"
            }),0);
        }        
    }

    toggleMenu(option:IMenu) {
        if(option.name === 'company') {
            this.items = this.companyItems;
            this.headerService.changeHeader(EntityName.COMPANY, true, 
                                                        EntityName.COMPANY, {showClientView:true});
        } else if (option.name === 'home') {
            this.items = this.getVisibleMenuItems(this.home, option);
            this.headerService.clearReleaseSection();
        } else if( option.name === 'preferences' ) {
            this.items = this.getVisibleMenuItems(this.preferencesItems, option);
            this.headerService.changeHeader(EntityName.PREFERENCES, true,
                                        EntityName.PREFERENCES, {showClientView:false}, '',
                                        null, () => false);
        } else if( option.name === 'site' ) {
            this.items = this.getVisibleMenuItems(this.siteItems, option);
            this.headerService.changeHeader(EntityName.SITE, true,
                                        EntityName.SITE, {showClientView:false});
        } else if( option.name === 'pipeline' ) {
            this.items = this.getVisibleMenuItems(this.pipelineItems, option);
            this.headerService.changeHeader(EntityName.ASSET_PIPELINE, true, 
                                                        EntityName.ASSET_PIPELINE, {showClientView:true});
        } else if( option.name === 'transmissionLine' ) {
            this.items = this.getVisibleMenuItems(this.transmissionLineItems, option);
            this.headerService.changeHeader(EntityName.ASSET_TRANSMISSION_LINE, true, 
                                                        EntityName.ASSET_TRANSMISSION_LINE, {showClientView:true});
        } else if( option.name === 'plant' ) {
            this.items = this.getVisibleMenuItems(this.plantItems, option);
            this.headerService.changeHeader(EntityName.PLANT, true, 
                                                        EntityName.PLANT, {showClientView:true});
        } else if( option.name === 'plantLtsa' ) {
            this.items = this.getVisibleMenuItems(this.plantLtsaItems, option);
            this.headerService.changeHeader(EntityName.PLANT_LTSA, true, 
                                                        EntityName.PLANT_LTSA, {showClientView:true});
        } else if( option.name === 'unitLtsa' ) {
            this.items = this.getVisibleMenuItems(this.unitLtsaItems, option);
            this.headerService.changeHeader(EntityName.UNIT_LTSA, true, 
                                                        EntityName.UNIT_LTSA, {showClientView:true});
        } else if( option.name === 'pipelineLtsa' ) {
            this.items = this.getVisibleMenuItems(this.pipelineLtsaItems, option);
            this.headerService.changeHeader(EntityName.PIPELINE_LTSA, true, 
                                                        EntityName.PIPELINE_LTSA, {showClientView:false});
        } else if( option.name === 'confirmedProject' ) {
            this.items = this.getVisibleMenuItems(this.confirmedProjectItems, option);
            this.headerService.changeHeader(EntityName.PROJECT_CONFIRMED, true,
                                                        EntityName.PROJECT_CONFIRMED, {showClientView:true});
        } else if( option.name === 'unconfirmedProject' ) {
            this.items = this.getVisibleMenuItems(this.unconfirmedProjectItems, option);
            this.headerService.changeHeader(EntityName.PROJECT_UNCONFIRMED, true,
                                                        EntityName.PROJECT_UNCONFIRMED, {showClientView:true});
        } else if( option.name === 'unit' ) {
            this.items = this.getVisibleMenuItems(this.unitItems, option);
            this.headerService.changeHeader(EntityName.UNIT, true,
                                                        EntityName.UNIT, {showClientView:true});
        } else if( option.name === 'tank' ) {
            this.items = this.getVisibleMenuItems(this.tankItems, option);
            this.headerService.changeHeader(EntityName.TANK, true,
                                                        EntityName.TANK, {showClientView:false});
        } else if( option.name === 'drive' ) {
            this.items = this.getVisibleMenuItems(this.driveItems, option);
            this.headerService.changeHeader(EntityName.TURBINE, true,
                                                        EntityName.TURBINE, {showClientView:true});
        } else if( option.name === 'otherEquipment' ) {
            this.items = this.getVisibleMenuItems(this.equipmentItems, option);
            this.headerService.changeHeader(EntityName.OEQUIPMENT, true,
                                                        EntityName.OEQUIPMENT, {showClientView:true});                                               
        } else if( option.name === 'miningEquipment' ) {
            this.items = this.getVisibleMenuItems(this.mEquipmentItems, option);
            this.headerService.changeHeader(EntityName.MEQUIPMENT, true,
                                                        EntityName.MEQUIPMENT, {showClientView:true});                                                
        } else if( option.name === 'umbrellaProject' ) {
            this.items = this.getVisibleMenuItems(this.umbrellaProjectItems, option);
            this.headerService.changeHeader(EntityName.UMBRELLA_PROJECT, true,
                                                        EntityName.UMBRELLA_PROJECT, 
                                                        {
                                                            showClientView:false,
                                                            showPDFLink: false
                                                        });
        } else if( option.name === 'area' ) {
            this.items = this.getVisibleMenuItems(this.areaItems, option);
            this.headerService.changeHeader(EntityName.AREA, true,
                                        EntityName.AREA, {showClientView:true});
        } else if( option.name === 'boiler' ) {
            this.items = this.getVisibleMenuItems(this.boilerItems, option);
            this.headerService.changeHeader(EntityName.BOILER, true,
                                        EntityName.BOILER, {showClientView:true});
        } else if( option.name === 'release' ) {
            this.items = this.getVisibleMenuItems(this.releaseItems, option);
        } else if( option.name === 'offlineEvent' ) {
            this.items = this.getVisibleMenuItems(this.offlineEventItems, option);
            this.headerService.changeHeader(EntityName.OFFLINE_EVENT, true,
                                    EntityName.OFFLINE_EVENT, {showClientView:true});
        } else if( option.name === 'geography' ) {
            this.items = this.getVisibleMenuItems(this.geographyItems, option);
            this.headerService.changeHeader(EntityName.GEOGRAPHY, true,
                                    EntityName.GEOGRAPHY, {showClientView:false, showPDFLink: false, updateTitleOnChangeRelease: false});
        } else if( option.name === 'regionAlerting' ) {
            this.items = this.getVisibleMenuItems(this.regionAlertingItems, option);
            this.headerService.changeHeader(EntityName.REGION_ALERTING, true,
                                    EntityName.REGION_ALERTING, {showClientView:false});
        } else if( option.name === 'subscriptionEdit' ) {
            this.items = this.getVisibleMenuItems(this.subscriptionEditItems, option);
            this.headerService.changeHeader('Edit Subscription', true,
                                    EntityName.SUBSCRIPTION_EDIT, {showClientView:false, showPDFLink: false, updateTitleOnChangeRelease: false});
        } else if( option.name === 'subscriptionCopy' ) {
            this.items = this.getVisibleMenuItems(this.subscriptionCopyItems, option);
            this.headerService.changeHeader('Copy Subscription', true,
                                    EntityName.SUBSCRIPTION_COPY, {showClientView:false, showPDFLink: false, updateTitleOnChangeRelease: false});
        }
        
        this.addItemsActions(this.items, option);
    }

    addItemsActions(items: MenuItem[], option:IMenu) {
        if(option.menuItems != null && items != null && items.length > 0) {
            for (const menuItem of option.menuItems) {
                menuItem.command = (click) => {this.scrollToSection(menuItem.sectionName)}
            }
            items[0].items = option.menuItems;
        }
    }

    private goTo(path: string, target: string): void {
        window.open(location.pathname + `#/ED/${path}`, target.replace(/\s+/g, '_'));
    }

    private openClearLockUtility():void {
        const data=null;
        const modalRef = this.dialogService.open(ClearLockUtilityComponent, {
            header: EntityName.CLEAR_LOCKS,
            width: '60rem',
            data: data,
            draggable: true,
            keepInViewport: true
        });
        this.onScrollOption.emit();
        this.cellContainerService.registerDialog(modalRef);
   }

    getVisibleMenuItems(menu: Array<MenuItem>, option: IMenu): Array<MenuItem> {
        if(menu.length === 1) {
            if(option.changeVisibility){
                const childMenuItem = menu[0].items.find(i => i.label === option.changeVisibility.label);
                if(childMenuItem) {
                    childMenuItem.visible = option.changeVisibility.visible;
                }
            }
            if(option.changeVisibilities){
                option.changeVisibilities.forEach(iMenu =>{
                    const childMenuItem = menu[0].items.find(i => i.label === iMenu.label);
                    if(childMenuItem) {
                        childMenuItem.visible = iMenu.visible;
                    }
                });
            }
            const menuClone = {...menu[0], items: menu[0].items.filter(i => i.visible)};
            return [menuClone];
        }

        return menu;
    }

    saveEntity() {
      this.entityCommonService.sendSaveEvent();
    }

}
