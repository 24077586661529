import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { InternalAttachmentComponent } from 'src/app/components/internal-attachment/internal-attachment.component';
import { HttpLoaderFactory } from './app.module';
import { CommonDirectivesModule } from './common-directives.modules';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AssetsPathComponent } from './components/assets-path/assets-path.component';
import { AssetsProjectComponent } from './components/assets-project/assets-project.component';
import { AuditEntityData } from './components/audit-entity-data/audit-entity-data.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { LastPrevDataComponent } from './components/global/last-prev-data/last-prev-data.component';
import { VerifiedFaxComponent } from './components/global/verified-fax/verified-fax.component';
import { GreenhouseGasEmissionsComponent } from './components/greenhouse-gas-emissions/greenhouse-gas-emissions.component';
import { LockedEntityModalComponent } from './components/messages/locked-entity-modal/locked-entity-modal.component';
import { SaveDialogComponent } from './components/messages/save-dialog/save-dialog.component';
import { AddressImportModalComponent } from './components/modals/address-import-modal/address-import-modal.component';
import { AlertModalComponent } from './components/modals/alert-modal/alert-modal.component';
import { ClearLockUtilityComponent } from './components/modals/clear-lock-utility/clear-lock-utility.component';
import { ContactModalComponent } from './components/modals/contact-modal/contact-modal.component';
import { FuelTypeSearchComponent } from './components/modals/fueltype-search/fueltype-search.component';
import { LocationSearchComponent } from './components/modals/location-search/location-search.component';
import { SearchAreaComponent } from './components/modals/search-area-modal/search-area-modal.component';
import { SearchEquipmentComponent } from './components/modals/search-equipment/search-equipment.component';
import { SearchModalComponent } from './components/modals/search-modal/search-modal.component';
import { SearchModalOfflineEventComponent } from './components/modals/search-offline-event-modal/search-offline-event-modal.component';
import { SearchPlantComponent } from './components/modals/search-plant/search-plant.component';
import { SicCodeLookupComponent } from './components/modals/sic-code-lookup/sic-code-lookup.component';
import { ViewCellContainerComponent } from './components/modals/view-cell-container/view-cell-container.component';
import { NotesComponent } from './components/notes/notes.component';
import { OffshoreAddressFormComponent } from './components/offshore-address-form/offshore-address-form.component';
import { OutputProductComponent } from './components/output-product/output-product.component';
import { OwnershipComponent } from './components/ownership/ownership.component';
import { PlantProductComponent } from './components/plant-product/plant-product.component';
import { RelatedInfoAreaComponent } from './components/related-info/area/area.component';
import { RelatedInfoBaseComponent } from './components/related-info/base/relatedinfo-base.component';
import { RelatedInfoBoilerComponent } from './components/related-info/boiler/boiler.component';
import { RelatedInfoCompanyComponent } from './components/related-info/company/company.component';
import { RelatedInfoDriveComponent } from './components/related-info/drive/drive.component';
import { RelatedInfoEquipmentComponent } from './components/related-info/equipment/equipment.component';
import { RelatedInfoMiningEquipmentComponent } from './components/related-info/mining-equipment/mining-equipment.component';
import { RelatedInfoNewsComponent } from './components/related-info/news/news.component';
import { RelatedInfoOfflineEventComponent } from './components/related-info/offline-event/offline-event.component';
import { OperatorComponent } from './components/related-info/operator/operator.component';
import { OwnerComponent } from './components/related-info/owner/owner.component';
import { RelatedInfoPipelineComponent } from './components/related-info/pipeline/pipeline.component';
import { RelatedInfoPlantLtsaComponent } from './components/related-info/plant-ltsa/plant-ltsa.component';
import { PlantComponent } from './components/related-info/plant/plant.component';
import { ConfirmedProjectComponent } from './components/related-info/project/confirmed-project.component';
import { UnConfirmedProjectComponent } from './components/related-info/project/unconfirmed/unconfirmed-project.component';
import { RelatedInfoSiteComponent } from './components/related-info/site/site.component';
import { RelatedInfoTankComponent } from './components/related-info/tank/tank.component';
import { RelatedInfoTransmissionLineComponent } from './components/related-info/transmission-line/transmission-line.component';
import { RelatedInfoUnitLtsaComponent } from './components/related-info/unit-ltsa/unit-ltsa.component';
import { RelatedInfoUnitComponent } from './components/related-info/unit/unit.component';
import { ContactComponent } from './contact-details-section/contact/contact.component';
import { PoolTableComponent } from './contact-details-section/contact/pool-table/pool-table.component';
import { SlottedTableComponent } from './contact-details-section/contact/slotted-table/slotted-table.component';
import { BasicNumberDirective } from './core/directives/basic-number.directive';
import { CompositeIdDirective } from './core/directives/composite-id.directive';
import { FocusElementDirective } from './core/directives/focus-element.directive';
import { FocusableTableMVTDirective } from './core/directives/focusable-table-mvt.directive';
import { FocusableTableDirective } from './core/directives/focusable-table.directive';
import { appFormattedWholeNumberDirective } from './core/directives/formatted-whole-number-directive';
import { IdFilterDirective } from './core/directives/id-filter.directive';
import { IgnoreHeadersDirective } from './core/directives/ignore-headers.directive';
import { LocationDirective } from './core/directives/location-directive';
import { LowercaseDirective } from './core/directives/lowercase.directive';
import { MaxDigitsDirective } from './core/directives/max-digits.directive';
import { OnlyNumbersDirective } from './core/directives/only-numbers.directive';
import { PercentageDirective } from './core/directives/percentage-directive';
import { StringUtilsDirective } from './core/directives/string-utils.directive';
import { UppercaseDirective } from './core/directives/uppercase.directive';
import { Constants } from './core/models/constants';
import { UnitLevelComponent } from './drive-section/unit-level/unit-level.component';
import { ServiceAgreementComponent } from './ltsa-section/service-agreement/service-agreement.component';
import { PrimeNgModule } from './primeng.module';
import { AmountPipe } from './shared/amount.pipe';
import { BigNumberPipe } from './shared/big-number.pipe';
import { GenericEntitySelectorComponent } from './shared/components/base/genericEntitySelector/generic-entity-selector.component';
import { LookUpModalComponent } from './shared/components/base/look-up-modal/look-up-modal.component';
import { MVTEntityAssociatorComponent } from './shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { SearchEntityModalComponent } from './shared/components/base/search-entity-modal/search-entity-modal.component';
import { SelectedItemsModalComponent } from './shared/components/base/selected-items-modal/selected-items-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNgModule,
        FormsModule,
        RouterModule,
        CommonDirectivesModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            }
        }),
        NgxMaskDirective,
        NgxMaskPipe

    ],
    declarations: [
        SaveDialogComponent,
        OwnershipComponent,
        AddressFormComponent,
        ContactComponent,
        PoolTableComponent,
        SlottedTableComponent,
        NotesComponent,
        EmailVerificationComponent,
        LowercaseDirective,
        OnlyNumbersDirective,
        appFormattedWholeNumberDirective,
        MaxDigitsDirective,
        IdFilterDirective,
        PercentageDirective,
        BasicNumberDirective,
        FocusElementDirective,
        CompositeIdDirective,
        FocusableTableDirective,
        FocusableTableMVTDirective,
        IgnoreHeadersDirective,
        PlantComponent,
        ServiceAgreementComponent,
        OwnerComponent,
        OperatorComponent,
        RelatedInfoBaseComponent,
        RelatedInfoUnitComponent,
        RelatedInfoUnitLtsaComponent,
        RelatedInfoAreaComponent,
        RelatedInfoCompanyComponent,
        RelatedInfoDriveComponent,
        RelatedInfoBoilerComponent,
        RelatedInfoTransmissionLineComponent,
        RelatedInfoOfflineEventComponent,
        RelatedInfoPipelineComponent,
        RelatedInfoNewsComponent,
        RelatedInfoEquipmentComponent,
        ConfirmedProjectComponent,
        UnConfirmedProjectComponent,
        AuditEntityData,
        GreenhouseGasEmissionsComponent,
        OffshoreAddressFormComponent,
        AssetsPathComponent,
        SearchPlantComponent,
        SicCodeLookupComponent,
        AmountPipe,
        AssetsProjectComponent,
        AddressImportModalComponent,
        VerifiedFaxComponent,
        LastPrevDataComponent,
        FuelTypeSearchComponent,
        OutputProductComponent,
        PlantProductComponent,
        MVTEntityAssociatorComponent,
        LookUpModalComponent,
        SearchEntityModalComponent,
        InternalAttachmentComponent,
        AlertModalComponent,
        SearchModalComponent,
        SearchModalOfflineEventComponent,
        ContactModalComponent,
        LocationSearchComponent,
        StringUtilsDirective,
        BigNumberPipe,
        RelatedInfoEquipmentComponent,
        SearchAreaComponent,
        RelatedInfoSiteComponent,
        RelatedInfoMiningEquipmentComponent,
        RelatedInfoPlantLtsaComponent,
        RelatedInfoTankComponent,
        GenericEntitySelectorComponent,
        SearchEquipmentComponent,
        UnitLevelComponent,
        LockedEntityModalComponent,
        AlertsComponent,
        ClearLockUtilityComponent,
        ViewCellContainerComponent,
        SelectedItemsModalComponent
    ],
    exports: [
        SaveDialogComponent,
        OwnershipComponent,
        AddressFormComponent,
        ContactComponent,
        NotesComponent,
        EmailVerificationComponent,
        LowercaseDirective,
        OnlyNumbersDirective,
        appFormattedWholeNumberDirective,
        MaxDigitsDirective,
        IdFilterDirective,
        PercentageDirective,
        BasicNumberDirective,
        FocusElementDirective,
        CompositeIdDirective,
        FocusableTableDirective,
        FocusableTableMVTDirective,
        IgnoreHeadersDirective,
        PlantComponent,
        ServiceAgreementComponent,
        OwnerComponent,
        OperatorComponent,
        RelatedInfoBaseComponent,
        RelatedInfoUnitComponent,
        RelatedInfoUnitLtsaComponent,
        RelatedInfoAreaComponent,
        RelatedInfoDriveComponent,
        RelatedInfoBoilerComponent,
        RelatedInfoCompanyComponent,
        RelatedInfoTransmissionLineComponent,
        RelatedInfoOfflineEventComponent,
        RelatedInfoPipelineComponent,
        RelatedInfoNewsComponent,
        RelatedInfoEquipmentComponent,
        ConfirmedProjectComponent,
        UnConfirmedProjectComponent,
        AuditEntityData,
        GreenhouseGasEmissionsComponent,
        OffshoreAddressFormComponent,
        AssetsPathComponent,
        SearchPlantComponent,
        SicCodeLookupComponent,
        AmountPipe,
        AssetsProjectComponent,
        VerifiedFaxComponent,
        LastPrevDataComponent,
        FuelTypeSearchComponent,
        OutputProductComponent,
        PlantProductComponent,
        MVTEntityAssociatorComponent,
        LookUpModalComponent,
        SearchEntityModalComponent,
        InternalAttachmentComponent,
        AlertModalComponent,
        UppercaseDirective,
        StringUtilsDirective,
        BigNumberPipe,
        SearchAreaComponent,
        RelatedInfoSiteComponent,
        RelatedInfoMiningEquipmentComponent,
        RelatedInfoPlantLtsaComponent,
        RelatedInfoTankComponent,
        GenericEntitySelectorComponent,
        SearchEquipmentComponent,
        UnitLevelComponent,
        LocationDirective,
        NgxMaskDirective,
        NgxMaskPipe,
        AlertsComponent,
        ClearLockUtilityComponent,
        ViewCellContainerComponent,
        SelectedItemsModalComponent
    ],
    providers: [DecimalPipe, provideNgxMask(Constants.NGX_MASK_CONFIG)]
})
export class EntityCommonModule { }
